import { Injectable } from '@angular/core';
import {
    Actions,
    ofActionSuccessful,
    Select,
    Store,
} from '@ngxs/store';
import { Observable } from 'rxjs';

import { PERMISSIONS_PARAMS } from '~/app/core/constants/permissions-params.constants';
import { PERMISSIONS } from '~/app/core/constants/permissions.constants';
import {
    SIMULATION_HISTORICAL_MAX_COMPARED_ELEMENTS,
    SIMULATION_HISTORICAL_MAX_COMPARED_PORTFOLIOS,
} from '~/app/core/constants/simulation-historical.constants';
import { SIMULATION_PROJECTION_MAX_COMPARED_PORTFOLIOS } from '~/app/core/constants/simulation-projection.constants';
import {
    SIMULATION_SCENARIO_MAX_COMPARED_ELEMENTS,
    SIMULATION_SCENARIO_MAX_COMPARED_PORTFOLIOS,
    SIMULATION_SCENARIO_MAX_EVENTS,
} from '~/app/core/constants/simulation-scenario.constants';
import {
    GetAuthorisationAction,
    ResetAuthorisationAction,
} from '~/app/core/state/authorisation/authorisation.action';
import {
    AuthorisationState,
} from '~/app/core/state/authorisation/authorisation.state';
import { PermissionModel } from '~/app/shared/types/api/authorisation/permission-model.type';
import { PlanModel } from '~/app/shared/types/api/authorisation/plan-model.type';


@Injectable({
    providedIn: 'root',
})
export class AuthorisationFacade {
    @Select(AuthorisationState.getAuthorisationPermissions)
    public permissions$!: Observable<PermissionModel[]>;

    @Select(AuthorisationState.getAuthorisationPermissionsLoaded)
    public permissionsLoaded$!: Observable<boolean>;

    @Select(AuthorisationState.getAuthorisationPlan)
    public plan$!: Observable<PlanModel | null>;

    @Select(AuthorisationState.getAuthorisationEndValidity)
    public endValidity$!: Observable<string | undefined>;

    public constructor(
        private store: Store,
        private actions$: Actions,
    ) {
    }

    public onGetAuthorisationSuccess() {
        return this.actions$.pipe(
            ofActionSuccessful(GetAuthorisationAction),
        );
    }

    public getAuthorisationPermissionsKeysSnapshot(): string[] {
        return this.store.selectSnapshot(AuthorisationState.getAuthorisationPermissionsKeys);
    }

    public getProjectionElementMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_SAVING_PLAN_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_SAVING_PLAN_ELEMENT_MAX_PARAMS,
        ))?.value ?? SIMULATION_PROJECTION_MAX_COMPARED_PORTFOLIOS;
    }

    public getSimulationScenarioElementMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_SCENARIO_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_SCENARIO_ELEMENT_MAX_PARAMS,
        ))?.value ?? SIMULATION_SCENARIO_MAX_COMPARED_ELEMENTS;
    }

    public getSimulationScenarioEventMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_SCENARIO_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_SCENARIO_EVENT_MAX_PARAMS,
        ))?.value ?? SIMULATION_SCENARIO_MAX_EVENTS;
    }

    public getSimulationScenarioAllocationMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_SCENARIO_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_SCENARIO_ALLOCATION_MAX_PARAMS,
        ))?.value ?? SIMULATION_SCENARIO_MAX_COMPARED_PORTFOLIOS;
    }

    public getSimulationHistoricalElementMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_HISTORICAL_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_HISTORICAL_ELEMENT_MAX_PARAMS,
        ))?.value ?? SIMULATION_HISTORICAL_MAX_COMPARED_ELEMENTS;
    }

    public getSimulationHistoricalAllocationMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_HISTORICAL_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_HISTORICAL_ALLOCATION_MAX_PARAMS,
        ))?.value ?? SIMULATION_HISTORICAL_MAX_COMPARED_PORTFOLIOS;
    }

    public getSimulationHistoricalNumberSimulationMaxSnapshot(): number | null {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_HISTORICAL_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_HISTORICAL_PROCESS_MAX_PARAMS,
        ))?.value ?? null;
    }

    public getSimulationScenarioNumberSimulationMaxSnapshot(): number | null {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_SCENARIO_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_SCENARIO_PROCESS_MAX_PARAMS,
        ))?.value ?? null;
    }

    public getSimulationHistoricalNumberSimulationLaunchedSnapshot(): number {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_HISTORICAL_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_HISTORICAL_PROCESS_MAX_PARAMS,
        ))?.count || 0;
    }

    public getSimulationScenarioNumberSimulationLaunchedSnapshot(): number {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.SIMULATION_SCENARIO_PROCESS,
            PERMISSIONS_PARAMS.SIMULATION_SCENARIO_PROCESS_MAX_PARAMS,
        ))?.count || 0;
    }

    public getFundSearchExcelFundMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.FUND_SEARCH_EXCEL_PROCESS,
            PERMISSIONS_PARAMS.FUND_SEARCH_EXCEL_FUND_MAX_PARAMS,
        ))?.value ?? 0;
    }

    public getTopFundCategoriesElementMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.PORTFOLIO_TOP_FUND_MANAGE,
            PERMISSIONS_PARAMS.TOP_FUNDS_CATEGORIES_ELEMENT_MAX_PARAMS,
        ))?.value ?? 0;
    }

    public getFundSearchExcelFieldMaxSnapshot(): number {
        return this.store.selectSnapshot(AuthorisationState.getPermissionParamByPermissionKeyAndPermissionParamKey(
            PERMISSIONS.FUND_SEARCH_EXCEL_PROCESS,
            PERMISSIONS_PARAMS.FUND_SEARCH_EXCEL_FIELD_MAX_PARAMS,
        ))?.value ?? 0;
    }

    public getPlanSnapshot(): PlanModel | null {
        return this.store.selectSnapshot(AuthorisationState.getAuthorisationPlan);
    }

    public getEndValiditySnapshot(): string | undefined {
        return this.store.selectSnapshot(AuthorisationState.getAuthorisationEndValidity);
    }

    public resetAuthorisations() {
        return this.store.dispatch(new ResetAuthorisationAction());
    }

    public getAuthorisations(isAPICallForced?: boolean): Observable<unknown> {
        return this.store.dispatch(new GetAuthorisationAction(isAPICallForced));
    }
}
