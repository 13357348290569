export enum RiskIndicatorCode {
    PERF = 'PERF', // Performance
    ANRT = 'ANRT', // Annual rtn
    VOLT = 'VOLT', // Volatility
    SHRT = 'SHRT', // Sharpe ratio
    MADD = 'MADD', // Max drawdown
    RCTM = 'RCTM', // Recovery time
    CALR = 'CALR', // Calmar ratio
    VR5D = 'VR5D', // VaR95 Daily
    VR9D = 'VR9D', // VaR99 Daily
    VR5W = 'VR5W', // VaR95 Wkly
    VR9W = 'VR9W', // VaR99 Wkly
    RART = 'RART', // Risk Adjusted Rtn
    IART = 'IART', // Risk Adjusted Rtn
    ETYP = 'ETYP', // Ecart type de Rnd espéré
    MCFQ = 'MCFQ', // Fréquence Cycle de Marché,
    ABYR = 'ABYR', // Analyse Annuel
    MCCY = 'MCCY' // Cycle de Marché Années Cumulées
}
