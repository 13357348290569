export enum SimulationScenarioViewModeValue {
    COMPARISON_ANALYSIS = 'COMPARISON_ANALYSIS',
    CORRELATION = 'CORRELATION',
    PERF_100 = 'PERF_100',
    PORTFOLIOS_ALLOCATION = 'PORTFOLIOS_ALLOCATION',
    PORTFOLIOS_CONTRIBUTION = 'PORTFOLIOS_CONTRIBUTION',
    PORTFOLIOS_DIVERSIFICATION = 'PORTFOLIOS_DIVERSIFICATION',
    PORTFOLIOS_FUND_ANALYSIS = 'PORTFOLIOS_FUND_ANALYSIS',
    PORTFOLIOS_SCATTER = 'PORTFOLIOS_SCATTER',
    RISK_INDICATOR_COMPARISON = 'RISK_INDICATOR_COMPARISON',
    SCATTER = 'SCATTER'
}
