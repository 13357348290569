import {
    FUND_SEARCH_PERMISSIONS_PARAMS,
} from '~/app/shared/enums/permissions/params/fund-search-permissions-params.enum';
import {
    SIMULATION_HISTORICAL_PERMISSIONS_PARAMS,
} from '~/app/shared/enums/permissions/params/simulation-historical-permissions-params.enum';
import {
    SIMULATION_SAVING_PLAN_PERMISSIONS_PARAMS,
} from '~/app/shared/enums/permissions/params/simulation-projection-permissions-params.enum';
import {
    SIMULATION_SCENARIO_PERMISSIONS_PARAMS,
} from '~/app/shared/enums/permissions/params/simulation-scenario-permissions-params.enum';
import { TOP_FUNDS_PERMISSIONS_PARAMS } from '~/app/shared/enums/permissions/params/top-funds-permissions-params.enum';

export const PERMISSIONS_PARAMS = {
    ...SIMULATION_SCENARIO_PERMISSIONS_PARAMS,
    ...SIMULATION_HISTORICAL_PERMISSIONS_PARAMS,
    ...SIMULATION_SAVING_PLAN_PERMISSIONS_PARAMS,
    ...FUND_SEARCH_PERMISSIONS_PARAMS,
    ...TOP_FUNDS_PERMISSIONS_PARAMS,
};
