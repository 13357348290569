export enum ActiveRiskCode {
    EPEF = 'EPEF', // Over Performance
    ERTN = 'ERTN', // Excess return
    BETA = 'BETA', // Beta
    TKER = 'TKER', // Tracking error
    IFRT = 'IFRT', // Info Ratio
    ARTN = 'ARTN', // Alpha Rtn (annualisé)
    APRF = 'APRF', // Alpha Rtn (non annualisé)
    BRTN = 'BRTN', // Beta Rtn
    EBRN = 'EBRN', // Excess Beta Rtn
    RAAC = 'RAAC', // Alpha Rnd ajusté au risque
    RATC = 'RATC', // Contrib de la gestion Beta
    RAVC = 'RAVC', // Contribution de la Gestion de volatilité
    RASC = 'RASC', // Asset selection
    UPRT = 'UPRT', // Ratio up
    DNRT = 'DNRT', // Ratio down
    IRAC = 'IRAC', // Ratio Information Alpha
    IRBC = 'IRBC', // Ratio Information Beta
    SRAC = 'SRAC', // Alpha SharpeRatio
    SRBC = 'SRBC', // Beta SharpeRatio
    BPRF = 'BPRF', // Perf. Beta
    ERAR = 'ERAR', // Sur Rnd ajusté au risque
    ERRB = 'ERRB', // Rnd de Excess Beta ajusté au risque
    TEAC = 'TEAC', // Alpha TE
    TEBC = 'TEBC', // Excess Beta TE
    VOLA = 'VOLA', // Volatilité d'Alpha
    VOLB = 'VOLB', // Beta Volatilité
}
