import { ActiveRiskCode } from '~/app/shared/enums/active-risk-code.enum';
import { FacetType } from '~/app/shared/enums/facet-type.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import { SimulationHistoricalViewModeValue } from '~/app/shared/enums/simulation-historical-view-mode-value.enum';
import { AdvancedFilterBooleanValue } from '~/app/shared/types/advanced-filter/advanced-filter-boolean-value.type';
import { AdvancedFilterGeneric } from '~/app/shared/types/advanced-filter/advanced-filter-generic.type';
import { ScatterChartPairIndicator } from '~/app/shared/types/chart/scatter/scatter-chart-pair-indicator.type';
import { Option } from '~/app/shared/types/option.type';

export const SIMULATION_HISTORICAL_SHARE_LIST_KEY = 'SIMULATION_HISTORICAL';

export const SIMULATION_HISTORICAL_PORTFOLIO_LIST_KEY = 'SIMULATION_HISTORICAL';

export const SIMULATION_HISTORICAL_SHARE_LIST_EXTRA_FILTERS = [
    {
        uid: 'extra_active',
        name: 'Part active',
        type: FacetType.BOOLEAN,
        value: {
            property: 'isAlive',
            value: true,
        },
    } as AdvancedFilterGeneric<AdvancedFilterBooleanValue>,
];

export const SIMULATION_HISTORICAL_MAX_COMPARED_ELEMENTS = 20;

export const SIMULATION_HISTORICAL_MAX_COMPARED_PORTFOLIOS = 5;

export const RISK_INDICATORS_FOR_SIMULATION: RiskIndicatorCode[] = [
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.ANRT,
    RiskIndicatorCode.VOLT,
    RiskIndicatorCode.SHRT,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.RCTM,
    RiskIndicatorCode.VR5D,
    RiskIndicatorCode.VR9D,
    RiskIndicatorCode.VR5W,
    RiskIndicatorCode.VR9W,
];

export const ACTIVE_RISKS_FOR_SIMULATION: ActiveRiskCode[] = [
    ActiveRiskCode.BETA,
    ActiveRiskCode.APRF,
    ActiveRiskCode.TKER,
    ActiveRiskCode.IFRT,
    ActiveRiskCode.ERTN,
    ActiveRiskCode.UPRT,
    ActiveRiskCode.DNRT,
];

export const PERIODS_OPTIONS: {
    period: Period,
    label: string,
}[] = [
    {
        period: Period.P01M,
        label: 'simulation_historical.periods.last_month',
    },
    {
        period: Period.P03M,
        label: 'simulation_historical.periods.last_3_month',
    },
    {
        period: Period.P06M,
        label: 'simulation_historical.periods.last_6_month',
    },
    {
        period: Period.P09M,
        label: 'simulation_historical.periods.last_9_month',
    },
    {
        period: Period.P01Y,
        label: 'simulation_historical.periods.last_1_year',
    },
    {
        period: Period.P03Y,
        label: 'simulation_historical.periods.last_3_year',
    },
    {
        period: Period.P05Y,
        label: 'simulation_historical.periods.last_5_year',
    },
    {
        period: Period.P10Y,
        label: 'simulation_historical.periods.last_10_year',
    },
];

export const SIMULATION_HISTORICAL_VIEW_MODES_DEFAULT_VALUES = {
    MAIN: [
        SimulationHistoricalViewModeValue.PERF_100,
        SimulationHistoricalViewModeValue.RISK_INDICATOR_COMPARISON,
        SimulationHistoricalViewModeValue.COMPARISON_ANALYSIS,
        SimulationHistoricalViewModeValue.PORTFOLIOS_ALLOCATION,
    ],
    PRO: [
        SimulationHistoricalViewModeValue.PERF_100,
        SimulationHistoricalViewModeValue.RISK_INDICATOR_COMPARISON,
        SimulationHistoricalViewModeValue.SCATTER,
        SimulationHistoricalViewModeValue.COMPARISON_ANALYSIS,
        SimulationHistoricalViewModeValue.PORTFOLIOS_ALLOCATION,
        SimulationHistoricalViewModeValue.PORTFOLIOS_CONTRIBUTION,
        SimulationHistoricalViewModeValue.PORTFOLIOS_SCATTER,
        SimulationHistoricalViewModeValue.PORTFOLIOS_FUND_ANALYSIS,
    ],
    ADVANCED: [
        SimulationHistoricalViewModeValue.PERF_100,
        SimulationHistoricalViewModeValue.RISK_INDICATOR_COMPARISON,
        SimulationHistoricalViewModeValue.SCATTER,
        SimulationHistoricalViewModeValue.COMPARISON_ANALYSIS,
        SimulationHistoricalViewModeValue.CORRELATION,
        SimulationHistoricalViewModeValue.PORTFOLIOS_ALLOCATION,
        SimulationHistoricalViewModeValue.PORTFOLIOS_CONTRIBUTION,
        SimulationHistoricalViewModeValue.PORTFOLIOS_DIVERSIFICATION,
        SimulationHistoricalViewModeValue.PORTFOLIOS_SCATTER,
        SimulationHistoricalViewModeValue.PORTFOLIOS_FUND_ANALYSIS,
    ],
};

export const SIMULATION_HISTORICAL_VIEW_MODES_VALUES: Option<SimulationHistoricalViewModeValue>[] = [
    {
        id: SimulationHistoricalViewModeValue.PERF_100,
        label: 'simulation_historical.view_mode_values.perf_100',
    },
    {
        id: SimulationHistoricalViewModeValue.RISK_INDICATOR_COMPARISON,
        label: 'simulation_historical.view_mode_values.risk_indicator_comparison',
    },
    {
        id: SimulationHistoricalViewModeValue.COMPARISON_ANALYSIS,
        label: 'simulation_historical.view_mode_values.comparison_analysis',
    },
    {
        id: SimulationHistoricalViewModeValue.CORRELATION,
        label: 'simulation_historical.view_mode_values.correlation',
    },
    {
        id: SimulationHistoricalViewModeValue.SCATTER,
        label: 'simulation_historical.view_mode_values.risk_indicator_comparison_scatter',
    },
    {
        id: SimulationHistoricalViewModeValue.PORTFOLIOS_ALLOCATION,
        label: 'simulation_historical.view_mode_values.portfolios_allocation',
    },
    {
        id: SimulationHistoricalViewModeValue.PORTFOLIOS_CONTRIBUTION,
        label: 'simulation_historical.view_mode_values.portfolios_contribution',
    },
    {
        id: SimulationHistoricalViewModeValue.PORTFOLIOS_FUND_ANALYSIS,
        label: 'simulation_historical.view_mode_values.portfolios_fund_analysis',
    },
    {
        id: SimulationHistoricalViewModeValue.PORTFOLIOS_DIVERSIFICATION,
        label: 'simulation_historical.view_mode_values.portfolios_diversification',
    },
    {
        id: SimulationHistoricalViewModeValue.PORTFOLIOS_SCATTER,
        label: 'simulation_historical.view_mode_values.portfolios_scatter',
    },
];

export const SIMULATION_HISTORICAL_PAIR_INDICATOR: ScatterChartPairIndicator[] = [
    {
        indicators: {
            ordinate: RiskIndicatorCode.ANRT,
            abscissa: RiskIndicatorCode.VOLT,
        },
        order: RiskIndicatorCode.SHRT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.UPRT,
            abscissa: ActiveRiskCode.DNRT,
        },
        order: null,
        isAreaGroupEnabled: true,
    },
];

export const SIMULATION_HISTORICAL_PAIR_INDICATOR_BENCHMARK: ScatterChartPairIndicator[] = [
    {
        indicators: {
            ordinate: RiskIndicatorCode.ANRT,
            abscissa: RiskIndicatorCode.VOLT,
        },
        order: RiskIndicatorCode.SHRT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.ERTN,
            abscissa: ActiveRiskCode.TKER,
        },
        order: ActiveRiskCode.IFRT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.APRF,
            abscissa: ActiveRiskCode.BETA,
        },
        order: ActiveRiskCode.APRF,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.UPRT,
            abscissa: ActiveRiskCode.DNRT,
        },
        order: null,
        isAreaGroupEnabled: true,
    },
];

export const ACTIVE_RISKS_WITH_PTF_BENCHMARK: ActiveRiskCode[] = [
    ActiveRiskCode.UPRT,
    ActiveRiskCode.DNRT,
];
