import {
    Injectable,
} from '@angular/core';
import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import {
    of,
    tap,
} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthorisationApiService } from '~/app/core/services/api/authorisation/authorisation.api.service';
import {
    GetAuthorisationAction,
    ResetAuthorisationAction,
} from '~/app/core/state/authorisation/authorisation.action';
import { ParamModel } from '~/app/shared/types/api/authorisation/param-model.type';
import { PermissionModel } from '~/app/shared/types/api/authorisation/permission-model.type';
import { PlanModel } from '~/app/shared/types/api/authorisation/plan-model.type';


export interface AuthorisationStateModel {
    permissions: PermissionModel[],
    plan: PlanModel | null,
    startValidity?: string,
    endValidity?: string,
    permissionsLoaded: boolean,
}

const defaults = {
    permissions: [],
    plan: null,
    permissionsLoaded: false,
};

@State<AuthorisationStateModel>({
    name: 'authorisation',
    defaults,
})
@Injectable()
export class AuthorisationState {
    constructor(
        private authorisationAPIService: AuthorisationApiService,
    ) {
    }

    static getPermissionParamByPermissionKeyAndPermissionParamKey(permissionKey: string, permissionParamKey: string = '') {
        return createSelector([AuthorisationState], (state: AuthorisationStateModel) => {
            const selectedPermissionParams = this.getPermissionParamsByPermissionKey(permissionKey)(state);
            return selectedPermissionParams?.find((param) => param.key === permissionParamKey);
        });
    }


    static getPermissionParamsByPermissionKey(permissionKey: string): (state: AuthorisationStateModel) => ParamModel[] | undefined {
        return createSelector([AuthorisationState], (state: AuthorisationStateModel) => state.permissions
            .find((permission) => permission.key === permissionKey)?.params);
    }

    @Selector()
    static getAuthorisationPermissions(state: AuthorisationStateModel): PermissionModel[] {
        return state.permissions;
    }

    @Selector()
    static getAuthorisationPermissionsKeys(state: AuthorisationStateModel): string[] {
        return state.permissions.map((permission) => permission.key);
    }

    @Selector()
    static getAuthorisationPermissionsLoaded(state: AuthorisationStateModel): boolean {
        return state.permissionsLoaded;
    }

    @Selector()
    static getAuthorisationPlan(state: AuthorisationStateModel): PlanModel | null {
        return state?.plan ?? null;
    }

    @Selector()
    static getAuthorisationStartValidity(state: AuthorisationStateModel): string | undefined {
        return state.startValidity;
    }

    @Selector()
    static getAuthorisationEndValidity(state: AuthorisationStateModel): string | undefined {
        return state.endValidity;
    }

    @Action(GetAuthorisationAction)
    authorisation({ patchState }: StateContext<AuthorisationStateModel>, { isAPICallForced }: GetAuthorisationAction) {
        return this.authorisationAPIService.getAuthorisation(isAPICallForced).pipe(
            tap((authorisationResponse) => {
                patchState({
                    ...authorisationResponse,
                    permissionsLoaded: true,
                });
            }),
            catchError(() => of(undefined)),
        );
    }

    @Action(ResetAuthorisationAction)
    resetAuthorisation({ patchState }: StateContext<AuthorisationStateModel>) {
        patchState({
            ...defaults,
        });
    }
}
