import { HttpClient } from '@angular/common/http';
import {
    inject,
    Injectable,
} from '@angular/core';
import { Observable } from 'rxjs';

import { Memoize } from '~/app/core/decorators/memoize.decorators';
import { AuthenticationFacade } from '~/app/core/state/authentication/authentication.facade';
import { LicenceModel } from '~/app/shared/types/api/authorisation/license-model.type';

@Injectable({
    providedIn: 'root',
})
export class AuthorisationApiService {
    private authFacade = inject(AuthenticationFacade);

    private httpClient = inject(HttpClient);

    public getAuthorisation(isAPICallForced: boolean = false) {
        if (isAPICallForced) {
            return this.getAuthorisationFromApi();
        }
        const plan = this.authFacade.getUserSnapshot()?.licence?.plan?.name;
        return this.getAuthorisationRaw(plan);
    }

    @Memoize({
        isObservable: true,
    })
    private getAuthorisationRaw(_plan: string| null | undefined) {
        return this.getAuthorisationFromApi();
    }

    private getAuthorisationFromApi(): Observable<LicenceModel> {
        return this.httpClient.get<LicenceModel>('/members/me/licence');
    }
}
