import { ActiveRiskCode } from '~/app/shared/enums/active-risk-code.enum';
import { EChartScatterQuadrant } from '~/app/shared/enums/echart/echart-scatter-quadrant.enum';
import { MarkAreaAntiCorrelate } from '~/app/shared/enums/echart/mark-area/mark-area-anticorrelate.enum';
import { MarkAreaCorrelate } from '~/app/shared/enums/echart/mark-area/mark-area-correlate.enum';
import { MarkAreaDecorrelate } from '~/app/shared/enums/echart/mark-area/mark-area-decorrelate.enum';
import { MarkAreaGroupEnum } from '~/app/shared/enums/echart/mark-area/mark-area-group.enum';
import { MarkAreaDownTrend } from '~/app/shared/enums/echart/mark-area/mark-area-trend-down.enum';
import { Period } from '~/app/shared/enums/period.enum';
import { RiskIndicatorCode } from '~/app/shared/enums/risk-indicator-code.enum';
import { SimulationScenarioViewModeValue } from '~/app/shared/enums/simulation-scenario-view-mode-value.enum';
import { ScatterChartAreaGroup } from '~/app/shared/types/chart/scatter/scatter-chart-area-group.type';
import { ScatterChartPairIndicator } from '~/app/shared/types/chart/scatter/scatter-chart-pair-indicator.type';
import { Option } from '~/app/shared/types/option.type';


export const SIMULATION_SCENARIO_RESULT_URL = 'results';

export const SIMULATION_SCENARIO_MAX_COMPARED_ELEMENTS = 20;

export const SIMULATION_SCENARIO_MAX_COMPARED_PORTFOLIOS = 5;
export const SIMULATION_SCENARIO_MAX_EVENTS = 5;

export const RISK_INDICATORS_FOR_SIMULATION: RiskIndicatorCode[] = [
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.ANRT,
    RiskIndicatorCode.VOLT,
    RiskIndicatorCode.SHRT,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.RCTM,
    RiskIndicatorCode.VR5D,
    RiskIndicatorCode.VR9D,
    RiskIndicatorCode.VR5W,
    RiskIndicatorCode.VR9W,
];

export const DEFAULT_RISK_INDICATORS_FOR_SIMULATION: RiskIndicatorCode[] = [
    RiskIndicatorCode.PERF,
    RiskIndicatorCode.VOLT,
    RiskIndicatorCode.SHRT,
    RiskIndicatorCode.MADD,
    RiskIndicatorCode.RCTM,
];

export const ACTIVE_RISKS_FOR_SIMULATION: ActiveRiskCode[] = [
    ActiveRiskCode.BETA,
    ActiveRiskCode.APRF,
    ActiveRiskCode.TKER,
    ActiveRiskCode.IFRT,
    ActiveRiskCode.ERTN,
    ActiveRiskCode.UPRT,
    ActiveRiskCode.DNRT,
];

export const DEFAULT_ACTIVE_RISKS_FOR_SIMULATION: ActiveRiskCode[] = [
    ActiveRiskCode.BETA,
    ActiveRiskCode.APRF,
];

export const PERIODS_OPTIONS: {
    period: Period,
    label: string,
}[] = [
    {
        period: Period.P01M,
        label: 'simulation_historical.periods.last_month',
    },
    {
        period: Period.P03M,
        label: 'simulation_historical.periods.last_3_month',
    },
    {
        period: Period.P06M,
        label: 'simulation_historical.periods.last_6_month',
    },
    {
        period: Period.P09M,
        label: 'simulation_historical.periods.last_9_month',
    },
    {
        period: Period.P01Y,
        label: 'simulation_historical.periods.last_1_year',
    },
    {
        period: Period.P03Y,
        label: 'simulation_historical.periods.last_3_year',
    },
    {
        period: Period.P05Y,
        label: 'simulation_historical.periods.last_5_year',
    },
    {
        period: Period.P10Y,
        label: 'simulation_historical.periods.last_10_year',
    },
];

export const SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES = {
    MAIN: [
        SimulationScenarioViewModeValue.COMPARISON_ANALYSIS,
        SimulationScenarioViewModeValue.PERF_100,
        SimulationScenarioViewModeValue.PORTFOLIOS_ALLOCATION,
        SimulationScenarioViewModeValue.RISK_INDICATOR_COMPARISON,
        SimulationScenarioViewModeValue.SCATTER,
    ],
    PRO: [
        SimulationScenarioViewModeValue.COMPARISON_ANALYSIS,
        SimulationScenarioViewModeValue.PERF_100,
        SimulationScenarioViewModeValue.PORTFOLIOS_ALLOCATION,
        SimulationScenarioViewModeValue.PORTFOLIOS_CONTRIBUTION,
        SimulationScenarioViewModeValue.PORTFOLIOS_FUND_ANALYSIS,
        SimulationScenarioViewModeValue.PORTFOLIOS_SCATTER,
        SimulationScenarioViewModeValue.RISK_INDICATOR_COMPARISON,
        SimulationScenarioViewModeValue.SCATTER,
    ],
    ADVANCED: [
        SimulationScenarioViewModeValue.COMPARISON_ANALYSIS,
        SimulationScenarioViewModeValue.CORRELATION,
        SimulationScenarioViewModeValue.PERF_100,
        SimulationScenarioViewModeValue.PORTFOLIOS_ALLOCATION,
        SimulationScenarioViewModeValue.PORTFOLIOS_CONTRIBUTION,
        SimulationScenarioViewModeValue.PORTFOLIOS_DIVERSIFICATION,
        SimulationScenarioViewModeValue.PORTFOLIOS_FUND_ANALYSIS,
        SimulationScenarioViewModeValue.PORTFOLIOS_SCATTER,
        SimulationScenarioViewModeValue.RISK_INDICATOR_COMPARISON,
        SimulationScenarioViewModeValue.SCATTER,
    ],
};

export const SIMULATION_SCENARIO_VIEW_MODES_VALUES: Option<SimulationScenarioViewModeValue>[] = [
    {
        id: SimulationScenarioViewModeValue.PERF_100,
        label: 'simulation_historical.view_mode_values.perf_100',
    },
    {
        id: SimulationScenarioViewModeValue.RISK_INDICATOR_COMPARISON,
        label: 'simulation_historical.view_mode_values.risk_indicator_comparison',
    },
    {
        id: SimulationScenarioViewModeValue.SCATTER,
        label: 'simulation_historical.view_mode_values.risk_indicator_comparison_scatter',
    },
    {
        id: SimulationScenarioViewModeValue.COMPARISON_ANALYSIS,
        label: 'simulation_historical.view_mode_values.comparison_analysis',
    },
    {
        id: SimulationScenarioViewModeValue.CORRELATION,
        label: 'simulation_historical.view_mode_values.correlation',
    },
    {
        id: SimulationScenarioViewModeValue.PORTFOLIOS_ALLOCATION,
        label: 'simulation_historical.view_mode_values.portfolios_allocation',
    },
    {
        id: SimulationScenarioViewModeValue.PORTFOLIOS_CONTRIBUTION,
        label: 'simulation_historical.view_mode_values.portfolios_contribution',
    },
    {
        id: SimulationScenarioViewModeValue.PORTFOLIOS_FUND_ANALYSIS,
        label: 'simulation_historical.view_mode_values.portfolios_fund_analysis',
    },
    {
        id: SimulationScenarioViewModeValue.PORTFOLIOS_DIVERSIFICATION,
        label: 'simulation_historical.view_mode_values.portfolios_diversification',
    },
    {
        id: SimulationScenarioViewModeValue.PORTFOLIOS_SCATTER,
        label: 'simulation_historical.view_mode_values.portfolios_scatter',
    },
];

const PSEUDO_INFINITY = 9999999;

export const AREA_GROUPS: ScatterChartAreaGroup[] = [
    {
        type: MarkAreaGroupEnum.DECORRELATE,
        isEnabled: false,
        delimiter: {
            abscissa: -100,
            ordinate: 100,
        },
        area: [
            {
                type: MarkAreaDecorrelate.DYNAMIC,
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.TOP_LEFT,
                color: '#00991e', // Green01
                coord: {
                    origin: {
                        abscissa: -100,
                        ordinate: 100,
                    },
                    destination: {
                        abscissa: -PSEUDO_INFINITY,
                        ordinate: PSEUDO_INFINITY,
                    },
                },
            },
            {
                type: MarkAreaDecorrelate.BUYING_BIAS,
                color: '#00991e', // Green01
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.BOTTOM_RIGHT,
                coord: {
                    origin: {
                        abscissa: 0,
                        ordinate: 100,
                    },
                    destination: {
                        abscissa: -100,
                        ordinate: PSEUDO_INFINITY,
                    },
                },
            },
            {
                type: MarkAreaDecorrelate.SELLING_BIAS,
                color: '#00991e', // Green01
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.BOTTOM_LEFT,
                coord: {
                    origin: {
                        abscissa: -100,
                        ordinate: 0,
                    },
                    destination: {
                        abscissa: -PSEUDO_INFINITY,
                        ordinate: 100,
                    },
                },
            },
            {
                type: MarkAreaDecorrelate.CONSERVATIVE,
                color: '#00991e', // Green01
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.BOTTOM_RIGHT,
                coord: {
                    origin: {
                        abscissa: 0,
                        ordinate: 0,
                    },
                    destination: {
                        abscissa: -100,
                        ordinate: 100,
                    },
                },
            },
        ],
        quadrant: EChartScatterQuadrant.TOP_LEFT,
    },
    {
        type: MarkAreaGroupEnum.CORRELATE,
        isEnabled: false,
        delimiter: {
            abscissa: 100,
            ordinate: 100,
        },
        area: [
            {
                type: MarkAreaCorrelate.BEST,
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.TOP_LEFT,
                color: '#00991e', // Green01
                coord: {
                    origin: {
                        abscissa: 0,
                        ordinate: 100,
                    },
                    destination: {
                        abscissa: 100,
                        ordinate: PSEUDO_INFINITY,
                    },
                },
            },
            {
                type: MarkAreaCorrelate.AGGRESSIVE,
                color: '#f1832c', // Orange02
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.TOP_RIGHT,
                coord: {
                    origin: {
                        abscissa: 100,
                        ordinate: 100,
                    },
                    destination: {
                        abscissa: PSEUDO_INFINITY,
                        ordinate: PSEUDO_INFINITY,
                    },
                },
            },
            {
                type: MarkAreaCorrelate.DEFENSIVE,
                color: '#b3c809', // Green03
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.BOTTOM_LEFT,
                coord: {
                    origin: {
                        abscissa: 0,
                        ordinate: 0,
                    },
                    destination: {
                        abscissa: 100,
                        ordinate: 100,
                    },
                },
            },
            {
                type: MarkAreaCorrelate.BAD,
                color: '#e84a4a', // Red
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.BOTTOM_RIGHT,
                coord: {
                    origin: {
                        abscissa: 100,
                        ordinate: 0,
                    },
                    destination: {
                        abscissa: PSEUDO_INFINITY,
                        ordinate: 100,
                    },
                },
            },


        ],
        quadrant: EChartScatterQuadrant.TOP_RIGHT,
    },
    {
        type: MarkAreaGroupEnum.ANTICORRELATE,
        isEnabled: false,
        delimiter: {
            abscissa: -100,
            ordinate: -100,
        },
        area: [
            {
                type: MarkAreaAntiCorrelate.EFFICIENT,
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.TOP_LEFT,
                color: '#00991e', // Green01
                coord: {
                    origin: {
                        abscissa: -100,
                        ordinate: 0,
                    },
                    destination: {
                        abscissa: -PSEUDO_INFINITY,
                        ordinate: -100,
                    },
                },
            },
            {
                type: MarkAreaAntiCorrelate.PARTIAL,
                color: '#b3c809', // Green03
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.TOP_RIGHT,
                coord: {
                    origin: {
                        abscissa: 0,
                        ordinate: 0,
                    },
                    destination: {
                        abscissa: -100,
                        ordinate: -100,
                    },
                },
            },
            {
                type: MarkAreaAntiCorrelate.LEVERAGED_SALE,
                color: '#f1832c', // Orange02
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.BOTTOM_LEFT,
                coord: {
                    origin: {
                        abscissa: -100,
                        ordinate: -100,
                    },
                    destination: {
                        abscissa: -PSEUDO_INFINITY,
                        ordinate: -PSEUDO_INFINITY,
                    },
                },
            },
            {
                type: MarkAreaAntiCorrelate.EXPENSIVE,
                color: '#e84a4a', // Red
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.BOTTOM_RIGHT,
                coord: {
                    origin: {
                        abscissa: 0,
                        ordinate: -100,
                    },
                    destination: {
                        abscissa: -100,
                        ordinate: -PSEUDO_INFINITY,
                    },
                },
            },
        ],
        quadrant: EChartScatterQuadrant.BOTTOM_LEFT,
    },
    {
        type: MarkAreaGroupEnum.DOWN_TREND,
        isEnabled: false,
        delimiter: {
            abscissa: 100,
            ordinate: -100,
        },
        area: [
            {
                type: MarkAreaDownTrend.DOWN_TREND,
                color: '#f00',
                isEnabled: false,
                isActive: false,
                quadrant: EChartScatterQuadrant.ALL_QUADRANT,
                coord: {
                    origin: {
                        abscissa: 0,
                        ordinate: 0,
                    },
                    destination: {
                        abscissa: PSEUDO_INFINITY,
                        ordinate: -PSEUDO_INFINITY,
                    },
                },
            },
        ],
        quadrant: EChartScatterQuadrant.BOTTOM_RIGHT,
    },
];

export const PAIR_INDICATOR: ScatterChartPairIndicator[] = [
    {
        indicators: {
            ordinate: RiskIndicatorCode.ANRT,
            abscissa: RiskIndicatorCode.VOLT,
        },
        order: RiskIndicatorCode.SHRT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.UPRT,
            abscissa: ActiveRiskCode.DNRT,
        },
        order: null,
        isAreaGroupEnabled: true,
    },
];

export const PAIR_INDICATOR_BENCHMARK: ScatterChartPairIndicator[] = [
    {
        indicators: {
            ordinate: RiskIndicatorCode.ANRT,
            abscissa: RiskIndicatorCode.VOLT,
        },
        order: RiskIndicatorCode.SHRT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.ERTN,
            abscissa: ActiveRiskCode.TKER,
        },
        order: ActiveRiskCode.IFRT,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.APRF,
            abscissa: ActiveRiskCode.BETA,
        },
        order: ActiveRiskCode.APRF,
    },
    {
        indicators: {
            ordinate: ActiveRiskCode.UPRT,
            abscissa: ActiveRiskCode.DNRT,
        },
        order: null,
        isAreaGroupEnabled: true,
    },
];


export const ACTIVE_RISKS_WITH_PTF_BENCHMARK: ActiveRiskCode[] = [
    ActiveRiskCode.UPRT,
    ActiveRiskCode.DNRT,
];
