export enum Period {
    PYR = 'YR',
    PMTH = 'MTH',
    PSIC = 'SIC',
    P09W = '09W',
    PYTD = 'YTD',
    P01D = '01D',
    P07D = '07D',
    P01M = '01M',
    P02M = '02M',
    P03M = '03M',
    P06M = '06M',
    P09M = '09M',
    P01Y = '01Y',
    P02Y = '02Y',
    P03Y = '03Y',
    P04Y = '04Y',
    P05Y = '05Y',
    P06Y = '06Y',
    P07Y = '07Y',
    P08Y = '08Y',
    P10Y = '10Y',
}

export enum PeriodDurationInMonths {
    P01M = 1,
    P02M = 2,
    P03M = 3,
    P06M = 6,
    P09M = 9,
    P01Y = 12,
    P03Y = 36,
    P05Y = 60,
    P10Y = 120,
}
